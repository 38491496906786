<template>
  <div>
    <head-bar :title="`Foto`">
      <template v-slot:left>
        <head-bar-link @click="onBackClick">
          <img src="../assets/arrow-left.svg" alt="" aria-hidden="true" />
          Terug
        </head-bar-link>
      </template>

      <template v-slot:right>
        <head-bar-link @click="onDelete">
          <icon identifier="close" class-name="mr-1" /> Verwijder
        </head-bar-link>
      </template>
    </head-bar>

    <app-layout classes="photo">
      <div class="p-2 p-md-3" v-if="activeMediaItem">
        <div class="photo__media mb-2 mb-md-3">
          <img
            :src="activeMediaItem.url"
            :alt="activeMediaItem.title"
            width="640"
            height="640"
          >
        </div>

        <form method="GET" action="" ref="form">
          <div class="mb-2 mb-md-3">
            <label class="photo__label" for="photo-title">Titel</label>
            <input
              class="photo__field photo__title"
              type="text"
              id="photo-title"
              name="title"
              :value="activeMediaItem.title"
              placeholder="Nog geen titel, klik om te bewerken"
              @keyup="onTitleKeyUp"
              @blur="onTitleUpdate"
            >
          </div>

          <div>
            <label class="photo__label" for="photo-description">Omschrijving</label>
            <textarea
              class="photo__field photo__description"
              id="photo-description"
              name="description"
              :value="activeMediaItem.description"
              @keyup="onDescriptionKeyUp"
              @blur="onDescriptionUpdate"
              rows="3"
            />
          </div>
        </form>
      </div>
    </app-layout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppLayout from './AppLayout.vue';
import Icon from '../components/Icon.vue';
import HeadBar from '../components/HeadBar.vue';
import HeadBarLink from '../components/HeadBarLink.vue';

export default {
  inject: ['$meta'],

  components: {
    Icon,
    HeadBarLink,
    HeadBar,
    AppLayout,
  },

  created() {
    this.$store.dispatch('loadMediaItems');
    this.$store.commit('setActiveMediaItemId', this.$route.params.id);
  },

  unmounted() {
    this.$store.commit('setActiveMediaItemId', null);
  },

  computed: {
    ...mapGetters([
      'activeMediaItem',
    ]),
  },

  methods: {
    onBackClick() {
      this.$router.go(-1);
    },

    onTitleKeyUp(event) {
      if (event.key === 'Enter') {
        this.onTitleUpdate();
      }
    },

    onDescriptionKeyUp(event) {
      if (event.key === 'Enter') {
        this.onDescriptionUpdate();
      }
    },

    onTitleUpdate() {
      this.onUpdate();
    },

    onDescriptionUpdate() {
      this.onUpdate();
    },

    onUpdate() {
      const formData = new FormData(this.$refs.form);

      this.$store.dispatch('updateMediaItem', [this.activeMediaItem.id, formData]);
    },

    onDelete() {
      // eslint-disable-next-line no-alert
      if (!window.confirm('Weet je het zeker?')) {
        return;
      }

      this.$store.dispatch('deleteMediaItem', this.activeMediaItem.id)
        .then(() => {
          this.$router.push({ name: 'Pet' });
        });
    },
  },
};
</script>

<style>
.app-layout.photo {
  padding-top: 3.5rem;
}

.photo__media {
  border-radius: .875rem;
  overflow: hidden;
  border: 6px solid #fff;
}

.photo__media img {
  width: 100%;
  height: auto;
  display: block;
}

.photo__title {
  font-size: 1.25rem;
  font-weight: 700;
}

.photo__label {
  font-weight: 700;
  display: block;
  margin-bottom: .5rem;
}

.photo__field {
  background-color: #fff;
  border: 1px solid #ccc;
  color: var(--purple);
  padding: .5em .75em;
  display: block;
  width: 100%;
  font-size: 1rem;
}

textarea.photo__field {

}

.photo__field:focus {
  outline: none;
  border-color: var(--purple);
}
</style>
